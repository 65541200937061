import { createContext, MutableRefObject } from "react";
import { FunnelPageType } from "./MainContainer";

export type FrontendFunnelDataType = {
  activeFunnelPage: FunnelPageType;
  mobileMode: boolean;
  previewMode: boolean;
  presentMode: boolean;
  openSettings?: boolean;
  settingsTab?: number;
  leftSectionRef?: MutableRefObject<HTMLDivElement>;
};

type FunnelFlowDataType = {
  selectedRatingString: string | null;
  selectedRatingOn10Scale: number | null;
};

export type ReviewSiteLinkType = {
  category?: string;
  category_id?: string;
  icon_url?: string;
  logo_url?: string;
  sort_order?: number;
  title?: string;
  url?: string;
  monitor_url?: string;
  uuid?: string;
  switch_show?: boolean;
  switch_opens_in_new_win?: boolean;
};

export type FunnelBackendDataType = {
  site_attributes?: any;
  page_attributes?: any;
  links_attributes?: ReviewSiteLinkType[];
  content_modules_attributes?: any;
  config_modules_attributes?: any;
  location_attributes?: any;
  recipient_attributes?: any;
  recaptcha_site_key?: string;
  request_id?: string;
  updated_request_id?: string;
};

export type FunnelDataType = {
  backend: FunnelBackendDataType;
  frontend: FrontendFunnelDataType;
  funnelFlow: FunnelFlowDataType;
  user_permissions?: [string];
  flags: FunnelFlags
};

export type FunnelContextType = {
  funnelData: FunnelDataType;
  updateFrontend: () => void;
  updateBackend: () => void;
};

export type FunnelFlags = {
  show_banner: boolean;
}

export const FunnelContext = createContext<FunnelContextType>(null);
