import React, { FunctionComponent, useRef, useState } from "react";
import FunnelNav from "../../navigation";
import FunnelHeader from "../FunnelHeader";
import FunnelPageEditor from "../FunnelPageEditor";

import axios from "axios";
import {
  FunnelBackendDataType,
  FunnelContext,
  FunnelDataType,
  FunnelFlags,
} from "../FunnelContext";
import { getCSRFToken } from "@utils/utils";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as AlertProvider } from "react-alert";
import { AlertTemplate, options } from "@components/molecules/Alert";
import Theme from "@traject/reviewspod.base_ui.theme";
import {
  $ParentContainer,
  $LeftSection,
  $RightSection,
  $Banner,
} from "./MainContainer.sc";

const queryClient = new QueryClient();

export interface IMainProps {
  siteUUID: string;
  pageUUID: string;
  user_permissions: [string];
  backend: FunnelBackendDataType;
  flags: FunnelFlags;
}

export enum FunnelPageType {
  LandingPage,
  PositiveReviewPage,
  NegativeReviewPage,
  PublicReviewSites,
  DirectFeedback,
}

const MainContainer: FunctionComponent<IMainProps> = ({
  siteUUID,
  pageUUID,
  user_permissions,
  backend,
  flags,
}) => {
  const leftSectionRef = useRef<HTMLDivElement>(null);

  const [funnelData, setFunnelData] = useState<FunnelDataType>({
    backend: backend,
    frontend: {
      activeFunnelPage: FunnelPageType.LandingPage,
      mobileMode: false,
      previewMode: false,
      presentMode: false,
      openSettings: false,
      settingsTab: 1,
      leftSectionRef: leftSectionRef,
    },
    funnelFlow: {
      selectedRatingString: null,
      selectedRatingOn10Scale: null,
    },
    user_permissions,
    flags,
  });

  const updateFrontend = () => setFunnelData({ ...funnelData });
  const updateBackend = (notification: string = null) => {
    axios
      .post(
        "/funnel/update_funnel_data",
        {
          site_uuid: siteUUID,
          page_uuid: pageUUID,
          funnel_data: funnelData.backend,
        },
        {
          headers: { "X-CSRF-TOKEN": getCSRFToken() },
        }
      )
      .then((response) => {
        response.data &&
          setFunnelData({
            backend: response.data,
            frontend: funnelData.frontend,
            funnelFlow: funnelData.funnelFlow,
            user_permissions,
          });
        // use notification value when available
        // to show user that update succeeded
      })
      .catch((error) => {
        console.log(error.response?.data?.errors);
      });
  };

  return (
    <Theme theme="gus">
      <QueryClientProvider client={queryClient}>
        <FunnelContext.Provider
          value={{
            funnelData,
            updateFrontend,
            updateBackend,
          }}
        >
          <AlertProvider template={AlertTemplate} {...options}>
            <$ParentContainer>
              {!funnelData.frontend.previewMode && (
                <$LeftSection ref={leftSectionRef}>
                  <FunnelNav />
                </$LeftSection>
              )}
              <$RightSection
                previewMode={funnelData.frontend.previewMode}
                mobileMode={funnelData.frontend.mobileMode}
                backgroundColor={
                  funnelData.backend.config_modules_attributes.background_color
                }
              >
                {funnelData.flags.show_banner &&
              <$Banner>We are migrating our infrastructure for enhanced performance and reliability. Scheduled maintenance on Sunday, August 18, and Monday, August 19, from 11:00 PM to 5:00 AM (ET) may potentially make the service temporarily unavailable. For any issues, contact <a href="mailto:support@grade.us">support@grade.us</a></$Banner>}
                <FunnelHeader />
                <FunnelPageEditor />
              </$RightSection>
            </$ParentContainer>
          </AlertProvider>
        </FunnelContext.Provider>
      </QueryClientProvider>
    </Theme>
  );
};

export default MainContainer;
